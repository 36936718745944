import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SuccessfulSubmitMessage from '../components/SuccessfulSubmitMessage'

import '../styles/global.scss'

const messageAttributes = {
  primaryColor: '#543a96',
  title: 'Seu download foi feito com sucesso!',
  text: (
    <span>
      Para mais informações, entre em contato com a gente pelo e-mail:
      <br />
      <strong>contato@studiom4.com.br</strong>
    </span>
  )
}

const SucessfulPublicityFileDownload = () => (
  <Layout>
    <SEO
      title="Download Conteúdo Publicidade"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <SuccessfulSubmitMessage
      primaryColor={messageAttributes.primaryColor}
      title={messageAttributes.title}
      descriptionText={messageAttributes.text}
    />
  </Layout>
)

export default SucessfulPublicityFileDownload
